import React, { Component } from 'react';
import axios from './axios';
import './Login.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function handleLogout (event){
        event.preventDefault();
         axios.post('https://www.shapp-api.sofhare.com/logout/',{
          refresh_token:localStorage.getItem('refresh_token')
                 },{headers: {'Content-Type': 'application/json'}},
                 {withCredentials: true});
           localStorage.clear();
           axios.defaults.headers.common['Authorization'] = null;
           toast.success("Logout effettuato con successo!", {onClose: () => {window.location.href = '/Login';},autoClose: 1000});
           }

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
        };
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    onChange = (e) => this.setState({ [e.target.name]: e.target.value });


    handleSubmit(event) {
        event.preventDefault();

        axios.post('https://www.shapp-api.sofhare.com/api/token/',{
            username: this.state.user,
            password: this.state.password,
        } ,{headers:
                        {'Content-Type': 'application/json'}}).then(function (res){
            console.log(res)
            console.log(res.data.access)
            console.log( res.config.data)
            //console.log('dentro')
            localStorage.setItem('token', res.data.access);
            localStorage.setItem('user', res.config.data);
            localStorage.setItem('refresh_token', res.data.refresh);
            axios.defaults.headers.common['Authorization'] =
                                         `Bearer ${res.data['access']}`;

            window.location.href = '/shapp'

        }).catch(function (err){
            toast.error('User or password non corretti!', {autoClose:1000});
        })
    }


    render() {

        return (
            <div className="Principal">
             <div className="login-block">
                <br></br>
                <br></br>
                <form >
                    <div>
                        <label name="user-txt">User</label>
                    </div>
                        <br></br>
                    <div>
                        <input type="text" placeholder="Enter user" name="user" value={this.state.user} onChange={this.onChange}/>
                    </div>
                        <br></br>
                    <div>
                        <label name="pw-txt">Password</label>
                    </div>
                        <br></br>
                    <div>
                        <input type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.onChange}/>
                    </div>
                        <br></br>
                    <button variant="primary" type="submit" name="btn-login" onClick={this.handleSubmit} className="btn-block" style={{ maxWidth: '300px' }}>submit</button>
                </form>
			</div>

            </div>

        )
    }
}