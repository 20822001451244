import { MapContainer, Marker, Popup, TileLayer, ZoomControl, LayersControl, LayerGroup, useMap } from 'react-leaflet';
import axios from '../axios';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import Wkt from 'wicket';
import { myIconCPT, myIconCPTU, myIconCPTE, myIconDMT, myIconSDMT, myIconSONDAGGIO, myIconHVSR, myIconMASW, myIconDH, icon } from '../custom_icon';
import LeafletRuler from "../LeafletRuler";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import { useNavigate } from 'react-router-dom';
import './MapComponent.css';
import { DataContext } from '../context/DataContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function LeafletgeoSearch() {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({ provider, marker: { icon } });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [map]);

  return null;
}

const FilteredMarkers = ({ points, filters, icons, viewData,dwData,navigate }) => {
  const filteredPoints = useMemo(() => {
    return points.filter(point => filters.includes(point.tipo));
  }, [points, filters]);

  return (
    filteredPoints.map((point, i) => {
      const icon = icons[point.tipo];
      return (
        <Marker key={i} position={[point.coords[1], point.coords[0]]} icon={icon}>
          <Popup>
            ID: {point.id} <br />
            Tipologia: {point.tipo} <br />
            <button onClick={() => viewData(point.id, point.tipo)} style={buttonStyle}>Vedi dati</button>
            <br />
            <button onClick={() => dwData(point.id, point.tipo)} style={buttonStyle}>DL input</button>
          </Popup>
        </Marker>
      );
    })
  );
};

const buttonStyle = {
  fontFamily: 'roboto',
  fontSize: '11px',
  border: '2px solid #12455b',
  color: 'black',
  padding: '5px 16px',
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: 'white',
  marginTop: '3px'
};

export const MapComponent = ({ positionDefault = [44.36, 11.34], filters }) => {

  const {setprof,setparQC,setparfs,setparMV,setparMH,setparVS} = useContext(DataContext)

  const [geopoints, setGeopoints] = useState([]);
  const [sispoints, setSispoints] = useState([]);
  const [pageData, setPageData] = useState({ pagesis: 0, pagegeo: 0 });
  const [loading, setLoading] = useState({ loadingGeo: false, loadingSIS: false });
  const navigate = useNavigate();

  useEffect(() => {
    countDATA();
  }, []);

  useEffect(() => {
    if (pageData.pagegeo > 0) {
      renderData('geo');
    }
  }, [pageData.pagegeo]);

  useEffect(() => {
    if (pageData.pagesis > 0) {
      renderData('sis');
    }
  }, [pageData.pagesis]);



  const countDATA = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    try {
      const response = await axios.get('https://www.shapp-api.sofhare.com/counter/proves/');
      const page = Math.ceil(response.data.find(item => item.type === 'TOTSIS').Tot_geog / 10);
      const pageg = Math.ceil(response.data.find(item => item.type === 'TOTCPT').Tot_geog / 10);
      setPageData({ pagesis: page, pagegeo: pageg });
    } catch (error) {
      console.error('Error fetching page data:', error);
    }
  };

  const renderData = async (type) => {
    setLoading(prevLoading => ({ ...prevLoading, [`loading${type === 'geo' ? 'Geo' : 'SIS'}`]: true }));
    const totalPages = type === 'geo' ? pageData.pagegeo : pageData.pagesis;
    const url = type === 'geo' ? 'https://www.shapp-api.sofhare.com/datacpt/proves/' : 'https://www.shapp-api.sofhare.com/datasis/proves/';
    const setData = type === 'geo' ? setGeopoints : setSispoints;

    try {
      for (let page = 1; page <= totalPages; page++) {
        const response = await axios.get(`${url}?page=${page}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const newData = response.data.results.map(item => {
          const wkt_geom = item.geom.replace('SRID=4326;', '');
          const wkt = new Wkt.Wkt();
          wkt.read(wkt_geom);
          const geojson_geom = wkt.toJson();
          const coords2 = geojson_geom['coordinates'];
          return { coords: coords2, id: item.id, tipo: item.tipo_prova };
        });
        setData(prevPoints => [...prevPoints, ...newData]);
      }
    } catch (error) {
      console.error(`Error fetching ${type.toUpperCase()} data:`, error.response ? error.response.data : error.message || error);
    } finally {
      setLoading(prevLoading => ({ ...prevLoading, [`loading${type === 'geo' ? 'Geo' : 'SIS'}`]: false }));
    }
  };

  const { updateDataView } = useContext(DataContext);

  const viewData = (id, tipo) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        let urlData;

        if (tipo === 'CPT' || tipo === 'CPTE' || tipo === 'CPTU'|| tipo === 'DMT' || tipo === 'SDMT' || tipo === 'SONDAGGIO') {
            urlData = `https://www.shapp-api.sofhare.com/outcpt/${id}/proves/`;
        } else {
            urlData = `https://www.shapp-api.sofhare.com/outsis/${id}/proves/`;
        }

        axios.get(urlData)
            .then(res => {
                const responseData = res.data[0];
                const wkt_geom = responseData.geom.replace('SRID=4326;', '');
                const wkt = new Wkt.Wkt();
                wkt.read(wkt_geom);
                const geojson_geom = wkt.toJson();
                const coords = geojson_geom['coordinates'];
                const coords2 = [coords[1], coords[0]];


                if (tipo === 'CPT' || tipo === 'CPTE' || tipo === 'CPTU') {
                    const veryjson = responseData.output_data.replace(/'/g, '"');
                    const datjson = JSON.parse(veryjson);
                    const parQC = datjson[0].QC;
                    const prof = datjson[0].H;
                    const parfs = datjson[0].FS;

                    // Update the DataContext instead of navigating
                    updateDataView(id, parQC, parfs, prof, null, null, null);

                //aggiungere le condizioni per le DMT e SDMT e per i sondaggio
                } else {
                    const veryjson = responseData.data.replace(/'/g, '"');
                    const datjson = JSON.parse(veryjson);
                    const parMV = datjson['value'][0]['ModelV'];
                    const parMH = datjson['value'][0]['ModelH'];
                    const parVS = responseData.vsh;

                    // Show Vs,eq toast notification
                    toast.info('Vs,eq ' + parVS + ' m/s', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000
                    });

                    // Update the DataContext instead of navigating
                    updateDataView(id, null, null, null, parMV, parMH, parVS);
                }
            })
            .catch(errors => {
                console.error(errors);
            });
    };


  const dwData = (id, tipo) => {
    // Set Authorization header
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    // Determine the URL based on tipo
    let urlData;
    if (tipo === 'CPT' || tipo === 'CPTE' || tipo === 'CPTU') {
        urlData = `https://www.shapp-api.sofhare.com/outcpt/${id}/proves/`;
    } else {
        urlData = `https://www.shapp-api.sofhare.com/outsis/${id}/proves/`;
    }

    // Fetch data based on tipo
    axios.get(urlData)
        .then(res => {
            const responseData = res.data[0];
            let downloadFilename = "";
            let downloadData = {};

            if (tipo === 'CPT' || tipo === 'CPTE' || tipo === 'CPTU') {
                // Handle CPT data
                const veryjson = responseData.output_data.replace(/'/g, '"');
                const datjson = JSON.parse(veryjson);
                const parQC = datjson[0].QC;
                const prof = datjson[0].H;
                const parfs = datjson[0].FS;

                // Assign the data and filename for download
                downloadData = datjson;
                downloadFilename = `inputCPT-${id}.json`;



            } else {
                // Handle SIS data
                const veryjson = responseData.data.replace(/'/g, '"');
                const datjson = JSON.parse(veryjson);

                // Assign the data and filename for download
                downloadData = datjson;
                downloadFilename = `outSis-${id}.json`;

            }

            // Prepare data for downloading
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(downloadData));
            const dlAnchorElem = document.getElementById('downloadAnchorElem');

            // Set the download attributes and trigger the download
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", downloadFilename);
            dlAnchorElem.click();

        })
        .catch(errors => {
            console.log(errors);
        });
    };


  const geoIcons = {
    CPT: myIconCPT,
    CPTU: myIconCPTU,
    CPTE: myIconCPTE,
    DMT: myIconDMT,
    SDMT: myIconSDMT,
    SONDAGGIO: myIconSONDAGGIO,
  };

  const sisIcons = {
    HVSR: myIconHVSR,
    MASW: myIconMASW,
    DH: myIconDH,
  };

  return (
    <div className="general">
    <div id="legendContainer"></div>
      <div className='map1'>
        <a href="/#" id="downloadAnchorElem" style={{ display: "none" }}>a</a>
        <MapContainer center={positionDefault} zoom={10} zoomControl={false} style={{ height: '100%', width: '100%' }}>
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="CartoDB.DarkMatter">
              <TileLayer
                url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Esri.WorldImagery">
              <TileLayer
                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                attribution='&copy; <a href="https://www.esri.com/en-us/arcgis/products/arcgis-online/overview">Esri</a>'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="OpenStreetMap">
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="OpenTopoMap">
              <TileLayer
                url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            
            <LayersControl.Overlay  unchecked name="Vs30 Distribuitions" >
                        <TileLayer
                            url='https://repository.sofhare.com/mappe/vs30/{z}/{x}/{y}.png'
                            attribution='sofHare © 2023 Vs30 Distribuitions '
                            opacity={0.5}
                            eventHandlers={{
                              add: (e) => {
                                console.log("Added Layer:", e.target);
                                // Create a legend element with colors
                                const legend = document.createElement('div');

                                legend.style.position = 'absolute';


                                legend.style.zIndex = 3000;
                                legend.style.display = 'flex';
                                const items = [
                                {color:'rgba(48, 18, 59, 0.5)',label:'0'},
                                 {color:'rgba(40, 188, 235, 0.5)'},
                                 {color:'rgba(164, 252, 60, 0.5)',label:'180'},
                                  {color:'rgba(251, 126, 33, 0.5)'},
                                   {color:'rgba(122, 4, 3, 0.5)',label:'360'} ];

                                items.forEach((item,index) => {
                                  const legendItem = document.createElement('div');
                                  legendItem.style.display = 'flex'; // Display label and color box horizontally
                                  legendItem.style.flexDirection = 'column';
                                  legendItem.style.alignItems = 'center'; // Align items vertically in the center
                                  const colorBox = document.createElement('div');
                                  colorBox.style.backgroundColor = item.color;
                                  colorBox.style.width = '30px';
                                  colorBox.style.height = '15px';
                                  //colorBox.style.marginRight = '10px'; // Adjust the margin between color box and label
                                  legendItem.appendChild(colorBox);
                                  const label = document.createElement('div');
                                  label.textContent = item.label;
                                  label.style.alignItems = 'left';
                                  label.style.fontSize = '12px';
                                  label.style.fontWeight = "bold";
                                  legendItem.appendChild(label);
                                  legend.appendChild(legendItem);

                                });
                                // Append the legend to a container element
                                const container = document.getElementById('legendContainer'); // Replace 'legendContainer' with the actual ID of your container element
                                if (container) {
                                  container.appendChild(legend);
                                }
                                e.target.legend = legend;
                              },
                              remove: (e) => {
                                console.log("Removed layer:", e.target);
                                if (e.target && e.target.legend) {
                                const legend = e.target.legend;
                                console.log("Accessed legend:", legend);

                                // Remove the legend element from the container
                                legend.parentNode.removeChild(legend);

                                // Remove the legend property from the target
                                delete e.target.legend;
                              } else {
                                console.log("Legend is not properly stored or accessed.");
                                }
                              }
                           }}
                        />

                        </LayersControl.Overlay>


              <LayerGroup>
                <MarkerClusterGroup>
                  <FilteredMarkers points={geopoints} filters={Object.keys(filters).filter(key => filters[key])} icons={geoIcons} viewData={viewData} dwData={dwData} navigate={navigate} />
                </MarkerClusterGroup>
              </LayerGroup>

              <LayerGroup>
                <MarkerClusterGroup>
                  <FilteredMarkers points={sispoints} filters={Object.keys(filters).filter(key => filters[key])} icons={sisIcons} viewData={viewData} dwData={dwData} navigate={navigate} />
                </MarkerClusterGroup>
              </LayerGroup>

          </LayersControl>
          <LeafletRuler />
          <ZoomControl position="bottomright" />
          <LeafletgeoSearch />
        </MapContainer>
      </div>

      {(loading.loadingGeo || loading.loadingSIS) && (
        <div className="loading-indicator">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};




