import './ticket.css';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';




function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_u317szf', 'template_gyjtsht', e.target, 'Xl4cHzs5_rTnbe391')
      .then((result) => {
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
      }, (error) => {
          console.log(error.text);
      });
  }


function Ticket() {

        const navigate = useNavigate();

        return(
            <div>
                <div className="Principal_tk">
                     <div className="Title-sh" onClick={() => navigate('/sHapp')} style={{ cursor: 'pointer' }}>
                        <h1>sHApp</h1>
                     </div>
                    <div className="layertab_newdata_ticket">
                        {/* Title or bold text above the form */}
                        <h2 style={{ textAlign: 'center', color: 'black' }}>Ticket</h2>  {/* Example title in the center */}
                        <div className="form_data">

                           <form className="contact-form" onSubmit={sendEmail}>
                              <label>e-mail</label>
                              <br />
                              <input type="text" id='email' name="email" />
                              <br />

                              <br />
                              <label>Note</label>
                              <br />
                              <textarea name="note" />
                              <br />

                              <input type="submit" value="Invia richiesta" />
                           </form>
                          <br />
                        </div>
                    </div>
                </div>
            </div>
    );
}


export default Ticket;
