import { MapContainer, Marker, Popup, TileLayer,ZoomControl, LayersControl,useMap, useMapEvents, GeoJSON } from 'react-leaflet'

import React, { useState,useRef,useEffect } from 'react'
//import { geosearch } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import { icon,icon_new_data } from '../custom_icon';

import "react-leaflet-markercluster/dist/styles.min.css";
//import style from './MapComponent.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import "leaflet-geosearch/dist/geosearch.css";
import './MapComponent_Page3.css';
import {useNavigate} from 'react-router-dom';

import emailjs from 'emailjs-com';
import { PickerOverlay } from 'filestack-react';
import { toast } from 'react-toastify';
import { LatLngBounds } from "leaflet";
import axios from 'axios';
import jwtDecode from 'jwt-decode';



function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon
      }
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl)
  }, []);
  return null;
}



function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    console.log(e.target)
    emailjs.sendForm('service_u317szf', 'template_5dkxupb', e.target, 'Xl4cHzs5_rTnbe391')
      .then((result) => {
          toast.success("Prova caricata con successo!", {autoClose: 2000});
            //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
      }, (error) => {
          console.log(error.text);
      });
  }

 const usernameMap = {
        1: 'sofhare',
        2: 'servizi_geotecnici',
        3: 'rdr_geo',
        9: 'dnz_slv',
        10: 'castellari_ambiente',
        11: 'taba_S',
        12: 'pro_Cv',
        13: 'envia',
        14: 'test',
        };

export const MapComponent_Page3 = ({addMarker}) => {

  const mapRef = useRef();
  const inputRef = useRef(null);
  const [markers, setMarkers] = useState(null);
  const [manualMarks, setManualMarks] = useState([]);
  const [mapCenter, setMapCenter] = useState([44.36, 11.34]);

  const [coordinates, setCoordinates] = useState({ lat: '', lng: '' });

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [geojsonFiles, setGeojsonFiles] = useState([]);

  const [username, setUsername] = useState(null);

  const [openOverlayIndex, setOpenOverlayIndex] = useState(null);
   // New state to track if map click feature is active
  const [isMapClickActive, setIsMapClickActive] = useState(false);
  const isMapClickActiveRef = useRef(isMapClickActive);


  const options = {
    accept: [".zip",".rar"],
    allowManualRetry: true,
    fromSources: ["local_file_system","googledrive"],
    minFiles: 1,
    maxFiles: 5,
    onUploadDone(file) {
    document.getElementById('url_data').value = file.filesUploaded[0].url
        }
    }

  useEffect(() => {
    isMapClickActiveRef.current = isMapClickActive;
  }, [isMapClickActive]);

  // Function to toggle map click feature
  const toggleMapClick = () => {
    setIsMapClickActive((prev) => {
      const newState = !prev;
      isMapClickActiveRef.current = newState; // Update the ref to match the new state
      console.log('Map Click Active:', newState); // Log new state
      return newState;
    });
  };


  const handleMapClick = (e) => {
      console.log('isMapClickActive:', isMapClickActiveRef.current);
      console.log('Map clicked', e); // Log the click event
        if (!isMapClickActiveRef.current) return; // Only add marker if map click is active
        console.log('sonoattivo')
        const { lat, lng } = e.latlng;
        setOpenOverlayIndex(null);
        setManualMarks((prevMarks) => [...prevMarks, { lat, lng }]);
        setMapCenter([lat, lng]); // Update the center of the map
        const map = mapRef.current;
        if (map) {
          map.setView([lat, lng]);
        }
  };

  // Use this effect to attach the listener after the map is created
  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('click', handleMapClick);
    }
    return () => {
      if (map) {
        map.off('click', handleMapClick);
      }
    };
  }, [mapRef.current]);

  const addMapClickListener = (map) => {
        mapRef.current = map;
        mapRef.current.on("click", handleMapClick);
  };



  const handleAddMarker = () => {
        const inputCoordinates = inputRef.current.value.split(",");
        if (inputCoordinates.length === 2) {
           const [lat, lng] = inputCoordinates.map((coord) => parseFloat(coord));
        if (!isNaN(lat) && !isNaN(lng)) {
                setManualMarks({ lat, lng });
                setMapCenter([lat, lng]);
                const map = mapRef.current;
                if (map) {
                map.setView([lat, lng]);
                  }
                }
  };}

  // Function to remove a marker on double-click
  const handleMarkerDoubleClick = (index) => {
        setManualMarks((prevMarks) => prevMarks.filter((_, i) => i !== index));
  };

  const handleMarkerClick = (lat, lng, index) => {
        setCoordinates({ lat, lng }); // Update the coordinates state
        // No toggle here, only for button click
  };

   const togglePickerOverlay = (index) => {
        setOpenOverlayIndex(prevIndex => (prevIndex === index ? null : index)); // Close if already open
   };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          const usernameId = decodedToken.user_id; // Adjust this based on your token structure
          const username = usernameMap[usernameId];
          // Store the username in state
          setUsername(username);
          // Fetch project names from PHP script
          axios.get(`https://repository.sofhare.com/shnapp_repo/public_html/${username}/index.php`)
            .then(response => {
              const files = response.data;
              // Group files by project name
              const projectMap = files.reduce((acc, file) => {
                if (!acc[file.project]) {
                  acc[file.project] = [];
                }
                acc[file.project].push(file);
                return acc;
              }, {});
              setProjects(Object.keys(projectMap)); // Set project names for dropdown
              setGeojsonFiles(projectMap); // Store files grouped by project
            })
            .catch(error => {
              console.error('Error fetching GeoJSON files:', error);
            });
        }
      }, []);


    const handleProjectChange = async (e) => {
        const projectName = e.target.value; // Get the selected project name
        setSelectedProject(projectName); // Update the selected project state
        setMarkers([]); // Clear previous markers
        try {
            // Fetch the list of GeoJSON files for the selected project from your API
            const response = await axios.get(`https://repository.sofhare.com/shnapp_repo/public_html/${username}/index.php`); // Update this with your actual API URL
            const geojsonFiles = response.data; // Get the list of GeoJSON files

            // Filter GeoJSON files by the selected project
            const geojsonFilesForProject = geojsonFiles.filter(file => file.project === projectName);
            let allNewMarkers = []; // Initialize an array to hold all new markers

            const photoBaseUrl = `https://repository.sofhare.com/shnapp_repo/public_html/${username}/${projectName}/`

            // Iterate over the filtered GeoJSON files
            for (const file of geojsonFilesForProject) {
                console.log('Processing file:', file.name); // Log the file being processed

                // Create a new marker from the properties
                const newMarker = {
                    lat: file.coordinates[1], // Latitude from the coordinates array
                    lng: file.coordinates[0], // Longitude from the coordinates array
                    name: file.properties.name,        // Name
                    type: file.properties.type,        // Type of feature
                    photo: `${photoBaseUrl}${file.properties.photo}`      // Photo associated with the feature
                };

                allNewMarkers.push(newMarker); // Add the new marker to the array
            }
            // If any markers were added, update the state and set the map center
            if (allNewMarkers.length > 0) {
                setMarkers(allNewMarkers); // Update markers in state
                setMapCenter([allNewMarkers[0].lat, allNewMarkers[0].lng]); // Set map center to first marker
                const map = mapRef.current;
                if (map) {
                const zoomLevel = 10;
                map.setView([allNewMarkers[0].lat, allNewMarkers[0].lng,zoomLevel]);
                  }
            }
        } catch (apiError) {
            // Handle errors fetching the list of GeoJSON files
            if (apiError.response) {
                console.error('Error fetching GeoJSON files from API:', apiError.response.status, apiError.response.data);
            } else if (apiError.request) {
                console.error('Error fetching GeoJSON files from API: No response received', apiError.request);
            } else {
                console.error('Error fetching GeoJSON files from API:', apiError.message);
            }
        }
    };


 return (
        <div className="general">
            <div className="map3" style={{ position: 'relative' }}>

            {/* Button to toggle map click feature */}
        <button onClick={toggleMapClick} style={{
          position: 'absolute',
          bottom: '80px',
          left: '25px',
          zIndex: 800,
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: isMapClickActive ? 'green' : 'white',
          color: 'black',
          border: 'none',
          cursor: 'pointer'
        }}>
          {isMapClickActive ? 'Deactivate Add Marker' : 'Activate Add Marker'}
        </button>



              {/* Select dropdown with independent size and position on the left */}
              <select
                onChange={handleProjectChange}
                value={selectedProject}
                style={{
                  position: 'absolute',   // To place it on top of the map
                  bottom: '10px',            // Adjust top positioning as needed
                  left: '25px',           // Adjust left positioning as needed
                  zIndex: 800,            // Ensure it stays on top
                  width: '200px',
                  height: '40px',
                  fontSize: '16px',
                  background: 'white',
                  borderRadius: '5px',
                  padding: '5px'
                }}
              >
                <option value="">Select a Project</option>
                {projects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </select>

            <a href="/#" id="downloadAnchorElem" style={{ display:"none" }} >a</a>
                <MapContainer  center={mapCenter} zoom={10} zoomControl={false} whenCreated={addMapClickListener} style={{ height: '100%', width: '100%',borderRadius: '7px'}}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="CartoDB.DarkMatter">
                            <TileLayer
                                url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer unchecked name="Satellite ESRI">
                            <TileLayer
                                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                attribution='Map Tiles © 2022 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS,Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
                            />
                        </LayersControl.BaseLayer>
                      </LayersControl>
                    <ZoomControl position='topleft'/>

                   <LeafletgeoSearch />

                   {/* Render the markers */}
                   {markers && markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={[marker.lat, marker.lng]}
                            icon={icon_new_data}
                            eventHandlers={{
                                click: (e) => { // Add the click event handler to the marker
                                    e.preventDefault(); // Prevent default behavior to avoid unintended actions
                                    handleMarkerClick(marker.lat, marker.lng, index); // Handle marker click
                                }
                            }}
                        >
                            <Popup>
                                <div>
                                    <div className="form_data">
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input
                                                type="text"
                                                id="tipo_prova"
                                                name="tipo_prova"
                                                value={marker.name}
                                                required
                                            />
                                            <br/>
                                            <input
                                                type="text"
                                                id='email'
                                                name="user"
                                                value={username}
                                                required
                                                readOnly
                                            />
                                            <br/>
                                            <input
                                                type="text"
                                                id='coord'
                                                name="coordinate"
                                                value={`${marker.lat}, ${marker.lng}`}
                                                required
                                                readOnly
                                            />
                                            <br/>
                                            <input
                                                type="url"
                                                id="url_data"
                                                name="url-send"
                                                placeholder="load data"
                                            />
                                            <button
                                                type="button"
                                                className="attachment"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the click event from propagating to the Popup
                                                    togglePickerOverlay(index); // Toggle overlay visibility
                                                }} // Only toggle on button click
                                            >
                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                            </button>
                                            {openOverlayIndex === index && ( // Show PickerOverlay if this is the open one
                                                <PickerOverlay
                                                    apikey='A7UoAZ540SQK0rvnBnwPgz'
                                                    pickerOptions={options}
                                                />
                                            )}
                                            <br/>
                                            <a href={marker.photo} target="_blank" rel="noopener noreferrer" download>
                                                <img
                                                    src={marker.photo}
                                                    alt={marker.name}
                                                    style={{ width: '100px', height: 'auto', borderRadius: '5px' }}
                                                />
                                            </a>
                                            <br />
                                            <textarea
                                                id="note"
                                                name="note"
                                                defaultValue="-Inserisci note-"
                                            ></textarea>
                                            <br/>
                                            <input
                                                type="hidden"
                                                name="photo_url"
                                                value={marker.photo}
                                            />
                                            <input
                                                type="submit"
                                                value="Invia richiesta"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                   ))}

                   {/* Render the manual markers */}
                   {manualMarks.length > 0 && manualMarks.map((marker, index) => ( // Use index2 for manual markers
                        <Marker
                            key={`manual-${index}`} // Use index2 for unique key
                            position={[marker.lat, marker.lng]}
                            icon={icon_new_data}
                            eventHandlers={{
                                dblclick: () => handleMarkerDoubleClick(index), // Use index2
                                click: (e) => { // Handle marker click
                                    e.preventDefault(); // Prevent default behavior to avoid unintended actions
                                    handleMarkerClick(marker.lat, marker.lng, index); // Use index2 here as well
                                }
                            }}
                        >
                            <Popup>
                                <div>
                                    <h3>Nuova prova</h3>
                                    <div className="form_data">
                                        <form className="contact-form" onSubmit={sendEmail}>
                                            <input
                                                type="text"
                                                id="tipo_prova"
                                                name="tipo_prova"
                                                defaultValue="-Tipo/Nome prova-"
                                                required
                                            />
                                            <br />
                                            <input
                                                type="text"
                                                id='email'
                                                name="user"
                                                value={username}
                                                required
                                                readOnly
                                            />
                                            <br />
                                            <input
                                                type="text"
                                                id='coord'
                                                name="coordinate"
                                                value={`${marker.lat}, ${marker.lng}`} // Use marker's lat/lng
                                                required
                                                readOnly
                                            />
                                            <br />
                                            <input
                                                type="url"
                                                id="url_data"
                                                name="url-send"
                                                placeholder="load data"
                                            />
                                            <button
                                                type="button"
                                                className="attachment"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent popup from closing
                                                    togglePickerOverlay(index); // Toggle for this specific marker
                                                }}
                                            >
                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                            </button>
                                            {openOverlayIndex === index && ( // Show PickerOverlay if this is the open one
                                                <PickerOverlay
                                                    apikey='A7UoAZ540SQK0rvnBnwPgz'
                                                    pickerOptions={options}
                                                />
                                            )}
                                            <br />
                                            <textarea
                                                id="note"
                                                name="note"
                                                defaultValue="-Inserisci note-"
                                            ></textarea>
                                            <br />
                                            <input
                                                type="submit"
                                                value="Invia richiesta"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                   ))}
                </MapContainer>

            </div>

        </div>

  )

}
