import { MapContainer, Marker, Popup, TileLayer,ZoomControl, LayersControl,useMap, useMapEvents, GeoJSON } from 'react-leaflet'

import React, { useState,useRef,useEffect } from 'react'
//import { geosearch } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import { icon,icon_new_data } from '../custom_icon';

import "react-leaflet-markercluster/dist/styles.min.css";
//import style from './MapComponent.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import "leaflet-geosearch/dist/geosearch.css";
import './MapComponent_Page3.css';
import {useNavigate} from 'react-router-dom';

import emailjs from 'emailjs-com';
import { PickerOverlay } from 'filestack-react';
import { toast } from 'react-toastify';
import { LatLngBounds } from "leaflet";
import axios from 'axios';
import jwtDecode from 'jwt-decode';



function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon
      }
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl)
  }, []);
  return null;
}


 const usernameMap = {
        1: 'sofhare',
        2: 'servizi_geotecnici',
        3: 'rdr_geo',
        9: 'dnz_slv',
        10: 'castellari_ambiente',
        11: 'taba_S',
        12: 'pro_Cv',
        13: 'envia',
        14: 'test',
        };

export const MapComponent_Page2 = ({addMarker}) => {

  const mapRef = useRef();
  const inputRef = useRef(null);
  const [markers, setMarkers] = useState(null);
  const [manualMarks, setManualMarks] = useState([]);
  const [mapCenter, setMapCenter] = useState([44.36, 11.34]);

  const [coordinates, setCoordinates] = useState({ lat: '', lng: '' });

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [geojsonFiles, setGeojsonFiles] = useState([]);

  const [username, setUsername] = useState(null);


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          const usernameId = decodedToken.user_id; // Adjust this based on your token structure
          const username = usernameMap[usernameId];
          // Store the username in state
          setUsername(username);
          // Fetch project names from PHP script
          axios.get(`https://repository.sofhare.com/shnapp_repo/public_html/${username}/index.php`)
            .then(response => {
              const files = response.data;
              // Group files by project name
              const projectMap = files.reduce((acc, file) => {
                if (!acc[file.project]) {
                  acc[file.project] = [];
                }
                acc[file.project].push(file);
                return acc;
              }, {});
              setProjects(Object.keys(projectMap)); // Set project names for dropdown
              setGeojsonFiles(projectMap); // Store files grouped by project
            })
            .catch(error => {
              console.error('Error fetching GeoJSON files:', error);
            });
        }
      }, []);

    const handleProjectChange = async (e) => {
        const projectName = e.target.value; // Get the selected project name
        setSelectedProject(projectName); // Update the selected project state
        setMarkers([]); // Clear previous markers
        try {
            // Fetch the list of GeoJSON files for the selected project from your API
            const response = await axios.get(`https://repository.sofhare.com/shnapp_repo/public_html/${username}/index.php`); // Update this with your actual API URL
            const geojsonFiles = response.data; // Get the list of GeoJSON files

            // Filter GeoJSON files by the selected project
            const geojsonFilesForProject = geojsonFiles.filter(file => file.project === projectName);
            let allNewMarkers = []; // Initialize an array to hold all new markers

            const photoBaseUrl = `https://repository.sofhare.com/shnapp_repo/public_html/${username}/${projectName}/`

            // Iterate over the filtered GeoJSON files
            for (const file of geojsonFilesForProject) {
                console.log('Processing file:', file.name); // Log the file being processed

                // Create a new marker from the properties
                const newMarker = {
                    lat: file.coordinates[1], // Latitude from the coordinates array
                    lng: file.coordinates[0], // Longitude from the coordinates array
                    name: file.properties.name,        // Name
                    type: file.properties.type,        // Type of feature
                    photo: `${photoBaseUrl}${file.properties.photo}`      // Photo associated with the feature
                };

                allNewMarkers.push(newMarker); // Add the new marker to the array
            }
            // If any markers were added, update the state and set the map center
            if (allNewMarkers.length > 0) {
                setMarkers(allNewMarkers);
                // Fit map to markers' bounds
                const bounds = new LatLngBounds(
                  allNewMarkers.map((marker) => [marker.lat, marker.lng])
                );
                const map = mapRef.current;
                if (map) {
                  map.fitBounds(bounds, { padding: [50, 50] });
                }
              }
        } catch (apiError) {
            // Handle errors fetching the list of GeoJSON files
            if (apiError.response) {
                console.error('Error fetching GeoJSON files from API:', apiError.response.status, apiError.response.data);
            } else if (apiError.request) {
                console.error('Error fetching GeoJSON files from API: No response received', apiError.request);
            } else {
                console.error('Error fetching GeoJSON files from API:', apiError.message);
            }
        }
    };

    // Custom component to capture the map instance
    const SetMapInstance = () => {
        const map = useMap();
        useEffect(() => {
          mapRef.current = map;
        }, [map]);
        return null;
    };


      // Function to download GeoJSON file of all markers
      const downloadGeoJSON = () => {
            if (!markers || markers.length === 0) {
              console.error('No markers available to download.');
              return;
            }

            // Construct GeoJSON object
            const geojson = {
              type: 'FeatureCollection',
              features: markers.map((marker) => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [marker.lng, marker.lat],
                },
                properties: {
                  name: marker.name,
                  type: marker.type,
                  photo: marker.photo,
                },
              })),
            };

            // Convert GeoJSON object to string
            const geojsonStr = JSON.stringify(geojson, null, 2);

            // Create a blob from the string
            const blob = new Blob([geojsonStr], { type: 'application/geo+json' });

            // Create a URL for the blob and trigger download
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${selectedProject || 'markers'}.geojson`; // Use the project name for the file
            a.click();

            // Clean up the URL object
            URL.revokeObjectURL(url);
      };

 return (
        <div className="general">
            <div className="map3" style={{ position: 'relative' }}>
               {/* Add the download button */}
                  <button
                    onClick={downloadGeoJSON}
                    style={{
                              position: 'absolute',   // To place it on top of the map
                              bottom: '80px',            // Adjust top positioning as needed
                              left: '25px',           // Adjust left positioning as needed
                              zIndex: 800,            // Ensure it stays on top
                              width: '200px',
                              height: '40px',
                              fontSize: '16px',
                              background: 'white',
                              borderRadius: '5px',
                              padding: '5px'
                            }}
                  >
                    Download progetto
                  </button>
              {/* Select dropdown with independent size and position on the left */}
              <select
                onChange={handleProjectChange}
                value={selectedProject}
                style={{
                  position: 'absolute',   // To place it on top of the map
                  bottom: '10px',            // Adjust top positioning as needed
                  left: '25px',           // Adjust left positioning as needed
                  zIndex: 800,            // Ensure it stays on top
                  width: '200px',
                  height: '40px',
                  fontSize: '16px',
                  background: 'white',
                  borderRadius: '5px',
                  padding: '5px'
                }}
              >
                <option value="">Select a Project</option>
                {projects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </select>

            <a href="/#" id="downloadAnchorElem" style={{ display:"none" }} >a</a>
                <MapContainer  center={mapCenter} zoom={10} zoomControl={false} style={{ height: '100%', width: '100%',borderRadius: '7px'}}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="CartoDB.DarkMatter">
                            <TileLayer
                                url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer unchecked name="Satellite ESRI">
                            <TileLayer
                                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                                attribution='Map Tiles © 2022 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS,Getmapping, Aerogrid, IGN, IGP, and the GIS User Community'
                            />
                        </LayersControl.BaseLayer>
                      </LayersControl>
                    <ZoomControl position='topleft'/>

                    <SetMapInstance />

                   <LeafletgeoSearch />

                    {/* Render the markers on the map */}
                    {markers && markers.map((marker, index) => (
                        <Marker key={index} position={[marker.lat, marker.lng]} icon={icon_new_data}>
                            <Popup>
                                <div>
                                    <div className="form_data">
                                      <form className="contact-form" >
                                        <input type="text" id="tipo_prova" name="tipo_prova"  value={marker.name} required />
                                        <br/>
                                        <input type="text" id='email' name="user" value={username} required readOnly />
                                        <br/>
                                        <input type="text" id='coord' name="coordinate" value={`${marker.lat}, ${marker.lng}`} required readOnly />
                                        <br/>
                                        <a href={marker.photo} target="_blank" rel="noopener noreferrer" download>
                                        <img src={marker.photo} alt={marker.name} style={{ width: '100px', height: 'auto', borderRadius: '5px' }} />
                                        </a>
                                        <br/>
                                      </form>
                                    </div>
                                  </div>
                            </Popup>
                        </Marker>
                    ))}



                </MapContainer>

            </div>

        </div>

  )

}

