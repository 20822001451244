import 'leaflet/dist/leaflet.css';
import './footer-bs.css';
import { MapComponent_Page3 } from './components/MapComponent_Page3';
import { useNavigate } from 'react-router-dom';
import './shapp_p3.css';



function Shapp_p3() {

    const navigate = useNavigate();

    return(

       <div>
           <div className="Principal">
                 <div className="Title-sh" onClick={() => navigate('/sHapp')} style={{ cursor: 'pointer' }}>
                    <h1>sHApp</h1>
                 </div>

                 <div className="layoutapp3">
                      <div className="App3">
                          <MapComponent_Page3 />
                      </div>

                 </div>


           </div>
        </div>

    );
}


export default Shapp_p3;




