import React, { useState, useEffect, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from '../axios';
import { DataContext } from '../context/DataContext';

const fetchData = () => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios
      .get(`https://www.shapp-api.sofhare.com/counter/proves/`)
      .then((res) => {
        const filterhvsr = res.data.find(item => item.type === 'HVSR');
        const lenhvsr = filterhvsr ? filterhvsr.Tot_geog : 0;
        const filtermasw = res.data.find(item => item.type === 'MASW');
        const lenmasw = filtermasw ? filtermasw.Tot_geog : 0;
        const filterdh = res.data.find(item => item.type === 'DH');
        const lendh = filterdh ? filterdh.Tot_geog : 0;
        resolve({
          lenhvsr,
          lenmasw,
          lendh,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const Graphsis = () => {
  const [series, setSeries] = useState([0, 0, 0]);

  const { lenhvsr, lenmasw, lendh } = useContext(DataContext);

  const [options] = useState({
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontFamily: 'roboto',
        fontWeight: 800,
        colors: ['#CC929292'],
      },
      background: {
        enabled: true,
        foreColor: 'black',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#E9A628',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '40px',
              fontFamily: 'roboto',
              color: '#E9A628',
              offsetY: 20,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: 'Totale',
              color: '#E9A628',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      intersect: false,
      style: {
        fontSize: '10px',
        fontFamily: 'roboto',
      },
    },
    labels: ['HVSR', 'MASW', 'DH'],
    colors: ['#fc7c24', '#00a2e8', '#2cd45c'],
    chart: {
      foreColor: '#E9A628',
    },
    stroke: {
      colors: ['black'],
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '12px',
      fontFamily: 'roboto',
      fontWeight: 400,
      foreColor: '#E9A628',
    },
    responsive: [
      {
        breakpoint: 550,
        options: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '2px',
            },
          },
          tooltip: {
            style: {
              fontSize: '2px',
            },
          },
          chart: {
            width: '100%',
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      try {
        const res = await fetchData();
        if (isMounted) {
          setSeries([res.lenhvsr, res.lenmasw, res.lendh]);
        }
      } catch (err) {
        if (isMounted) {
          setSeries([0, 0, 0]);
        }
        console.error('Error fetching data:', err);
      }
    };

    if (lenhvsr === undefined || lenmasw === undefined || lendh === undefined) {
      getData();
    } else {
      setSeries([lenhvsr, lenmasw, lendh]);
    }

    return () => {
      isMounted = false;
    };
  }, [lenhvsr, lenmasw, lendh]);

  return (
    <ReactApexChart options={options} series={series} type="donut" />
  );
};









