import { MapComponent_Page2 } from './components/MapComponent_Page2';
import { useNavigate } from 'react-router-dom';
import './shapp_p3.css';


function Shapp_p2() {

    const navigate = useNavigate();

    return(
       <div>
           <div className="Principal">
                 <div className="Title-sh" onClick={() => navigate('/sHapp')} style={{ cursor: 'pointer' }}>
                    <h1>sHApp</h1>
                 </div>
                 <div className="layoutapp3">
                      <div className="App3">
                          <MapComponent_Page2 />
                      </div>
                 </div>
           </div>
        </div>
    );
}


export default Shapp_p2;



