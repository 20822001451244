import React, { useState, useEffect,useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import {DataContext} from '../context/DataContext'


export const PaneQC = () => {
  const {prof,parQC} = useContext(DataContext)
    const [series, setSeries] = useState([{data: [[0, 0], [0, 0], [0, 0] , [0, 0], [0, 0], [0, 0], [0, 0] , [0, 0]]}]);
    const [options] = useState({
           colors: ['#E9A628'],

           chart: {
           },

           stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 3,
                dashArray: 0,
            },

           grid: {
                  borderColor: '#ADADAD',
                  strokeDashArray: 6,
                  show: true,      // you can either change hear to disable all grids
                  xaxis: {
                    lines: {
                      show: true  //or just here to disable only x axis grids
                     }
                   },
                  yaxis: {
                    lines: {
                      show: true  //or just here to disable only y axis
                     }
                   }
             },

            yaxis: {
                min: 0,
                max: 30,
                show: true,
                reversed: true,
                labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: '#E9A628',
                            fontSize: '12px',
                            fontFamily: 'roboto',
                            fontWeight: 400,
                            },
                        rotate: 0,
                        },
                 axisBorder: {
                      show: false,
                      color: '#E9E9E9',
                      width: 2
                  },
                 axisTicks: {
                      show: true,
                      color: '#E9E9E9',
                      width: 6
                  },

                 title: {
                      text: 'prof. (m da p.c.)',
                      rotate: -90,
                      style: {
                          color: '#E9A628',
                          fontSize: '14px',
                          fontFamily: 'roboto',
                          fontWeight: 600,
                  },
                 },
            },

            xaxis: {
                type:'numeic',
                show: true,
                min: 0,
                max: 30,
                tickAmount: 6,
                labels: {
                        show: true,
                        style: {
                            colors: '#E9A628',
                            fontSize: '12px',
                            fontFamily: 'roboto',
                            fontWeight: 400,
                            },
                        rotate: 0,
                        },
                 axisBorder: {
                      show: false,
                      color: '#E9E9E9',
                      width: '100%'
                  },
                 axisTicks: {
                      show: true,
                      color: '#E9E9E9',
                      width: 6
                  },

                 title: {
                      text: 'Qc (MPa)',
                      rotate: 0,
                      style: {
                          color: '#E9A628',
                          fontSize: '14px',
                          fontFamily: 'roboto',
                          fontWeight: 600,
                  },
                 },
            },
                                });

    useEffect(() => {
      const getData = async () => {
          try {
            const my_data = []
            for (let i = 0; i < parQC.length; i++) {
              const parQC_ele = parQC[i];
              const profor = prof[i];
              const prof_ele= Math.round(profor * 10) / 10;
              let ele = [parQC_ele,prof_ele]
              my_data.push(ele)
            }
            setSeries([{data: my_data}]);
          }
          catch (err) {
            setSeries([{data: [[0, 0], [0, 0], [0, 0] , [0, 0], [0, 0], [0, 0], [0, 0] , [0, 0]]}]);
          }
        };
        getData();
    }, [parQC,prof]);

    return (
        <ReactApexChart options={options} series={series} type="line" height= '100%' width='100%' />
      );
}
